
(function (fbConfig) {
  'use strict';

  angular.module('firebase.config.file', [])
    .config(firebaseConfig);

  firebaseConfig.$inject = ['$windowProvider'];
  function firebaseConfig($windowProvider) {
    // Initialize Firebase
    $windowProvider.$get().firebase.initializeApp(fbConfig);
  }
  // eslint-disable-next-line no-inline-comments
}({"apiKey":"AIzaSyBSOxr6ZfxJRPX3dBqq-staeFMLkiO10BA","databaseURL":"https://pokermunity.firebaseio.com","storageBucket":"pokermunity.appspot.com","authDomain":"pokermunity.firebaseapp.com","messagingSenderId":"734384281646","projectId":"pokermunity"}));
